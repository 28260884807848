import PropTypes from 'prop-types';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

function Hero({
  label,
  labelCentered,
  preLabel,
  imageUrl,
  blurDataURL,
  alertBanner,
}) {
  const { t } = useTranslation('careers');
  const linkAlertBanner = (
    <>
      <p>
        {t`LINK_ALERT_TEXT_1` + ' '}
        <a
          className="c-font-bold c-underline"
          href="https://www.consumer.ftc.gov/articles/job-scams"
          target="_blank"
          rel="noreferrer">
          {t`LINK_ALERT_TEXT_LINK`}
        </a>
        {' ' + t`LINK_ALERT_TEXT_2`}
      </p>
    </>
  );

  return (
    <section className="c-hero c-w-full md:c-mb-24 rounded-lg">
      <div className="relative c-h-[496px]">
        <Image
          className="c-object-cover c-w-full"
          alt="hero image"
          src={imageUrl}
          layout="fill"
          placeholder={blurDataURL ? 'blur' : 'empty'}
          blurDataURL={blurDataURL}
        />
        <div
          className={`c-flex c-justify-center ${
            labelCentered ? 'c-flex-row' : 'c-justify-start c-flex-col'
          } c-items-center c-absolute c-top-0 c-left-0 px-6 py-4 c-w-full c-h-full c-z-10`}>
          {preLabel && (
            <h3 className="c-container c-mx-auto c-text-white c-text-sm c-mb-3 c-uppercase">
              {preLabel}
            </h3>
          )}
          <h1
            className={`${
              !labelCentered ? 'c-container c-mx-auto' : 'c-max-w-[810px]'
            } c-justify-center c-mb-3 c-text-6xl c-font-bold c-leading-[52px] c-text-white`}>
            {label}
          </h1>
        </div>
      </div>
      {alertBanner ? (
        <div className="relative c-min-h-fit c-py-3 c-px-3 c-bg-blue c-z-10 c-text-white c-text-sm c-leading-6">
          <div className="c-container c-mx-auto">{linkAlertBanner}</div>
        </div>
      ) : (
        ''
      )}
    </section>
  );
}

Hero.defaultProps = {
  label: '',
  preLabel: '',
  imageUrl: '',
  blurDataURL: '',
  labelCentered: false,
  alertBanner: true,
};

Hero.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  preLabel: PropTypes.string,
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  blurDataURL: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelCentered: PropTypes.bool,
  alertBanner: PropTypes.bool,
};

export default Hero;
