import React from 'react';
import PropTypes from 'prop-types';
import { buildHeading } from '../lib/build-component';
import OneTrustReactPlayer from './onetrust/OneTrustReactPlayer';

function Card({ image, videoUrl, title, text }) {
  return (
    <div className="c-max-w-sm md:c-w-full c-rounded-md c-shadow-card c-overflow-hidden c-w-full ">
      <OneTrustReactPlayer url={videoUrl} image={image} title={title} />
      {text && title && (
        <div className="c-px-5 c-pt-4 c-pb-6 md:c-px-6 md:c-min-h-[210px]">
          {buildHeading({
            level: 5,
            title,
            className: 'c-text-2xl c-mb-2',
          })}
          <p className="text-gray-700 text-base">{text}</p>
        </div>
      )}
    </div>
  );
}

Card.defaultProps = {
  title: '',
  text: '',
};

Card.propTypes = {
  image: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Card;
