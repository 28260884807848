import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { getSubNavMenuSticky } from '../lib/dataService';

export function useScrolled() {
  const getIsScroll = () => {
    const offset = window.scrollY;

    return offset > getSubNavMenuSticky;
  };

  const [scrolled, setScrolled] = useState(false);

  const { asPath } = useRouter();

  const handleScroll = () => {
    setScrolled(getIsScroll());
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
  }, []);

  return { scrolled, asPath };
}
