import { buildMapMarkers } from '../lib/build-component';
import useTranslation from 'next-translate/useTranslation';

function Map({ totalPositions, totalLocations, totalDepartments, locations }) {
  const marks = buildMapMarkers(locations);
  const { t } = useTranslation('careers');
  return (
    <div className="c-relative">
      <div className="map c-relative c-my-0 c-mx-auto">
        <img
          className="c-block c-max-w-full c-h-auto c-rounded-md"
          src="/static/utils/world-map.jpg"
          alt="World map of Unity offices"
        />
      </div>
      {marks}
      <h3 className="c-absolute c-px-7 md:c-px-0 c-text-center c-font-bold c-text-2xl c-text-[28px] md:c-text-2xl c-text-white c-bottom-4 c-left-[50%] c--translate-x-2/4 c-w-full">
        <span id="positions" className="b">
          {`${totalPositions} ` + t`MAP_SECTION.OPEN_POSITIONS_ACROSS` + ' '}
        </span>
        <span id="locations" className="b">
          {`${totalLocations} ` + t`MAP_SECTION.LOCATIONS_IN` + ' '}
        </span>
        <span id="departments" className="b">
          {`${totalDepartments} ` + t`MAP_SECTION.DEPARTMENTS`}
        </span>
      </h3>
    </div>
  );
}

export default Map;
