import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { GetStayHappyIcons } from '../lib/dataService';

const HoverTooltipDesktop = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0c7fda',
    padding: 20,
    textAlign: 'center',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 18,
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: 12,
    color: '#0c7fda',
  },
}));

const StayHappyIconMobile = ({ icon, text }) => {
  return (
    <div className="md:c-hidden">
      <div className="c-flex c-flex-col c-text-black">
        <div className="c-p-3 c-mb-4 material-icons c-self-center c-rounded-lg c-shadow-happyIcon">
          {icon}
        </div>
        <span className="c-self-center c-justify-center c-text-center">
          {text}
        </span>
      </div>
    </div>
  );
};

function StayHappyIcons() {
  const happyIcons = GetStayHappyIcons();
  return (
    <>
      <div className="c-w-full c-grid c-grid-cols-2 c-gap-5 md:c-hidden">
        {happyIcons.map((item, index) => {
          return (
            <StayHappyIconMobile
              key={index}
              icon={item.icon}
              text={item.text}
            />
          );
        })}
      </div>
      <div className="w-full hidden md:flex">
        <div className="c-items-center c-justify-between c-w-full c-flex-wrap c-hidden md:c-flex">
          {happyIcons.map((item, index) => {
            return (
              <HoverTooltipDesktop
                key={index}
                title={item.text}
                placement={item.placement}>
                <div className="c-rounded-lg c-text-black hover:c-text-white c-shadow-happyIcon c-p-0 c-w-[50px] c-h-[50px] c-cursor-pointer hover:c-bg-blue c-flex c-justify-center">
                  <span className="material-icons c-self-center">
                    {item.icon}
                  </span>
                </div>
              </HoverTooltipDesktop>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default StayHappyIcons;
