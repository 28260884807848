
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Head from 'next/head';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Button from '@unity-technologies/web-ui-components/components/Button/Button';
import { getPlaiceholder } from 'plaiceholder';
import { getHeaderData, getFooterLinks } from '../lib/header-footer-data';
import { BuildGridList, buildHeading, buildFAQ } from '../lib/build-component';
import { getDatalayerArgs, getLanguage } from '../lib/utils';
import { getAllDepartmentsJobs, GetFaqSections } from '../lib/dataService';
import Card from '../components/Card';
import MapComponent from '../components/Map';
import Search from '../assets/svg/search.svg';
import Hero from '../components/Hero';
import SubNavMenu from '../components/SubNavMenu';
import StayHappyIcons from '../components/StayHappyIcon';
import TagManager from 'react-gtm-module';
import { useScrolled } from '../hooks/useScrolled';
import useTranslation from 'next-translate/useTranslation';

export default function Home({
  locations,
  departments,
  totalPositions,
  heroImage,
  heroBlurDataUrl,
}) {
  const { t } = useTranslation('careers');
  const { scrolled } = useScrolled();
  const { asPath, locale } = useRouter();

  const datalayerArgs = getDatalayerArgs(asPath, {
    title: 'Unity Careers',
  });

  useEffect(() => {
    TagManager.dataLayer(datalayerArgs);
  }, [datalayerArgs]);

  const [currentTab, setCurrentTab] = useState('locations');
  const Faq = buildFAQ(GetFaqSections());

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const searchInput = form.querySelector('input');
    const { value } = searchInput;
    window.location.href = `/${locale}/find-position?text=${value}`;
  };

  const heroLabel = (
    <>
      <span>{t`MAIN_HERO_TEXT`}</span>
      <form className="c-relative c-flex c-mt-6" onSubmit={handleSubmit}>
        <input
          className="c-border c-rounded c-w-full c-text-black c-border-[#d6dadc] c-h-[52px] c-flex c-items-center hover:c-border-black focus-within:c-border-black c-font-normal"
          type="text"
          placeholder={t`FIND_A_POSITION`}
        />
        <Search className="c-icon-search c-fill-darkGray" />
      </form>
    </>
  );

  return (
    <>
      <Hero
        label={heroLabel}
        imageUrl={heroImage}
        blurDataURL={heroBlurDataUrl}
        labelCentered
      />
      <Head>
        <title>{t(`UNITY_CAREERS`)}</title>
      </Head>
      <section className="c-container c-mx-auto c-px-3 md:c-px-0 selection:c-bg-highlight">
        <SubNavMenu scrolled={scrolled} />
        <section className="c-py-4 md:c-py-7 c-relative c-flex c-flex-row c-justify-between c-items-center">
          <div className="c-flex c-flex-row c-justify-start c-text-xl">
            <span className="hidden md:c-block">{t`SEARCH_BY`}</span>
            <span
              role="button"
              tabIndex={0}
              className={`${
                currentTab === 'locations'
                  ? 'c-border-b-2 c-border-blue c-text-blue'
                  : ''
              } hover:c-text-blue c-cursor-pointer c-pb-2 c-ml-0 md:c-ml-3`}
              onClick={() => {
                setCurrentTab('locations');
              }}>
              {t`LOCATIONS`}
            </span>
            <span
              role="button"
              tabIndex={0}
              className={`${
                currentTab === 'departments'
                  ? 'c-border-b-2 c-border-blue c-text-blue'
                  : ''
              } hover:c-text-blue c-cursor-pointer c-pb-2 c-ml-3`}
              onClick={() => {
                setCurrentTab('departments');
              }}>
              {t`DEPARTMENTS`}
            </span>
          </div>
          <div
            className={`c-invisible c-block ${
              scrolled ? 'c-h-[120px] c--mt-[120px]' : 'c--mt-[95px]'
            }`}
            id="unity-open-positions"></div>
          <div className="c-hidden md:c-block">
            <Button
              block
              type="button"
              href={`/${locale}/find-position`}
              className="c-bg-blue c-font-semibold">
              {t`SEE_ALL_POSITIONS`}
            </Button>
          </div>
        </section>

        {currentTab === 'locations' && (
          <section className="c-py-4 md:c-pb-7">
            <div className="c-container c-grid c-grid-cols-1 c-gap-4 sm:c-grid-cols-2 md:c-grid-cols-4">
              {BuildGridList(locations)}
            </div>
          </section>
        )}
        {currentTab === 'departments' && (
          <section className="c-py-8">
            <div className="c-container c-grid c-grid-cols-1 c-gap-4 sm:c-grid-cols-2 md:c-grid-cols-4">
              {BuildGridList(departments, 'department')}
            </div>
          </section>
        )}

        <section className="c-py-4 md:c-py-7 c-flex c-flex-col">
          <MapComponent
            totalDepartments={departments.length}
            totalLocations={locations.length}
            totalPositions={totalPositions}
            locations={locations}
          />
        </section>

        <section className="c-py-4 md:c-py-7 c-relative c-flex c-flex-col-reverse md:c-flex-row-reverse md:c-justify-between">
          <div
            className={`c-invisible c-block  ${
              scrolled ? 'c-h-[127px] c--mt-[127px]' : 'c--mt-[28px]'
            }`}
            id="unity-students"></div>
          <div className="c-text-lg w-full md:c-w-1/3 c-leading-8 md:c-mt-5">
            {buildHeading({ level: 2, title: t`STUDENTS_SECTION.TITLE` })}
            <p className="c-mt-4">{t`STUDENTS_SECTION.TEXT`}</p>
            <div className="c-flex c-mt-5">
              <Button
                block
                type="button"
                href={`https://unity.com${
                  locale === 'en' ? '' : '/fr'
                }/students`}
                className="c-w-min c-mr-3 c-border-emphasized c-font-semibold c-bg-emphasized hover:c-border-emphasized hover:c-bg-emphasized hover:c-bg-opacity-80">
                {t`LEARN_MORE`}
              </Button>
              <Button
                block
                type="button"
                href={`/${locale}/find-position?department=students-early-career`}
                className="c-w-min c-bg-blue c-font-semibold">
                {t`OPEN_POSITIONS`}
              </Button>
            </div>
          </div>
          <div className="c-mb-5 md:c-mb-0 md:c-mt-0 md:c-mr-8 w-full md:c-w-2/3">
            <Card
              image="/static/utils/unity-interns.jpg"
              videoUrl="https://www.youtube.com/embed/b_zC1Xh9Jps?autoplay=1&amp;disablekb=1&amp;iv_load_policy=3&amp;modestbranding=1&amp;showinfo=0&amp;html5=1&amp;rel=0"
              title=""
              text=" "
            />
          </div>
        </section>

        <section className="c-py-4 md:c-py-7 c-relative c-flex c-flex-col">
          <div
            className={`c-invisible c-block ${
              scrolled ? 'c-h-[100px] c--mt-[100px]' : 'c--mt-[28px]'
            }`}
            id="unity-happy"></div>
          <div className="c-text-lg w-full md:c-w-2/3 c-leading-8">
            {buildHeading({ level: 2, title: t`HAPPY_SECTION.TITLE` })}
            <p className="c-mt-4">{t`HAPPY_SECTION.TEXT`}</p>
          </div>
          <div className="c-flex c-mt-5">
            <div className="component-stay-happy__icons c-flex c-flex-wrap c-w-full">
              <StayHappyIcons />
            </div>
          </div>
        </section>

        <section className="c-py-4 md:c-py-7 c-relative c-flex c-flex-col">
          <div
            className={`c-invisible c-block ${
              scrolled ? 'c-h-[130px] c--mt-[130px]' : 'c--mt-[10px]'
            }`}
            id="unity-faq"></div>
          <div className="c-text-lg w-full c-leading-8">
            {buildHeading({
              level: 2,
              title: t`FAQ_SECTION.TITLE`,
              className: 'c-mb-4',
            })}
            {Faq}
          </div>
        </section>
      </section>
    </>
  );
}

 async function _getStaticProps({ locale }) {
  const { locationLabels, departmentLabels, totalPositions } =
    await getAllDepartmentsJobs();
  const language = getLanguage(locale);
  const footer = await getFooterLinks(language);
  const header = await getHeaderData(language);
  const { base64, img } = await getPlaiceholder('/hero/hero.jpg', { size: 10 });

  return {
    props: {
      locations: [...locationLabels.values()],
      departments: [...departmentLabels.values()],
      totalPositions,
      header,
      heroImage: img,
      heroBlurDataUrl: base64,
      ...footer,
    },
    revalidate: parseInt(process.env.CACHE_REVALIDATION_TIME),
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (lang, ns) => import(`@unity-technologies/web-module-js-translations/locales/${{en:"en-US", fr: "fr-FR"}[lang]}/${ns}.json`).then(m => m.default),
            }))
          }
        }
    }
  