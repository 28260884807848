import { useRouter } from 'next/router';
import SubNav from '@unity-technologies/web-ui-components/components/SubNav/SubNav';
import Link from 'next/link';
import SubNavLink from '@unity-technologies/web-ui-components/components/SubNav/SubNavLink';
import useTranslation from 'next-translate/useTranslation';

const SubNavMenu = ({ scrolled }) => {
  const { push, locale } = useRouter();
  const { t } = useTranslation('careers');

  const items = [
    { title: t`SUBMENU_CAREERS.OPEN_POSITIONS`, url: '/#unity-open-positions' },
    {
      title: t`SUBMENU_CAREERS.STUDENTS`,
      url: '/#unity-students',
    },
    { title: t`SUBMENU_CAREERS.BENEFITS`, url: '/#unity-happy' },
    { title: t`SUBMENU_CAREERS.FAQ`, url: '/#unity-faq' },
  ];

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const searchInput = form.querySelector('input');
    const { value } = searchInput;
    push(
      {
        pathname: 'find-position',
        query: { text: value },
      },
      undefined,
      { shallow: false },
    );
  };

  // onClick event handler for items in subNav component
  const handleItemClick = item => {
    push(item.url);
  };

  return (
    <>
      <section className="c-py-4">
        <SubNav
          items={items}
          handleSubmit={handleSubmit}
          handleItemClick={handleItemClick}
          href={`/${locale}`}
          localizedText={{
            siteName: t(`UNITY_CAREERS`),
            searchLabel: t`SEARCH_POSITIONS`,
            more: 'More',
            moreUrl: '/more',
            locale,
          }}
        />
      </section>

      <nav
        className={`${
          scrolled ? 'c-hidden md:c-flex c-ease-in' : 'c-hidden c-ease-out'
        } c-sticky c-top-0 c-flex c-items-center c-z-[90] c-h-18 c-flex-no-wrap c-bg-black c-mx-auto c-bg-full-black c-transition c-duration-300`}
        aria-label="Sub menu">
        <SubNavLink
          className="c-text-white c-font-black c-text-lg c-leading-5 c-px-0 c-pr-5 c-py-3"
          href={`/${locale}`}
          passHref>
          {t(`UNITY_CAREERS`)}
        </SubNavLink>
        {items.map(item => (
          <Link key={item.title} href={item.url}>
            <a className="c-block c-font-medium c-py-2 c-mr-3 c-text-white hover:c-text-blue">
              {item.title}
            </a>
          </Link>
        ))}
      </nav>
    </>
  );
};

export default SubNavMenu;
