import ReactPlayer from 'react-player';
import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Button from '@unity-technologies/web-ui-components/components/Button';
import PropTypes from 'prop-types';
import { useOneTrustContext } from '../context/useOneTrustContext';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

/**
 * Mapper for human readable category name.
 * @param cookieCategory
 * @returns {*}
 */
const mapCookieCategory = cookieCategory => {
  const categories = {
    C0003: 'Functional Cookies',
    C0004: 'Targeting Cookies',
  };

  return categories[cookieCategory];
};

/**
 * onClick handler for OneTrust Cookie settings modal.
 * @param cookieCategory
 */
const displayCookieSettings = cookieCategory => {
  if (typeof document !== 'undefined') {
    document.getElementById(`ot-header-id-${cookieCategory}`).click();
    Array.prototype.forEach.call(
      document.getElementById('onetrust-consent-sdk').children,
      element => {
        if (element.classList) {
          element.classList.remove('ot-hide');
          element.removeAttribute('style');
        }
      },
    );
  }
};

/**
 * OneTrust Wrapper for Remote Video providers.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const OneTrustReactPlayer = props => {
  const { url, image, title } = props;
  const { oneTrustCookieState } = useOneTrustContext();
  const { t } = useTranslation('careers');
  const [isOpen, setOpen] = useState(false);
  const onOpenModal = () => {
    setOpen(!isOpen);
  };
  // Set default cookie category.
  let cookieCategory = 'C0004';

  // Update default category for vimeo provider.
  if (url && url.match('vimeo')) {
    cookieCategory = 'C0003';
  }

  return (
    <>
      {!oneTrustCookieState.categories[cookieCategory] ? (
        <div className="c-relative">
          <img className="c-w-full" src={image} alt={title} />
          <div className="c-absolute top-0 c-bottom-0 c-left-0 c-right-0 c-bg-[#000000b3] c-text-white c-text-center c-text-xs c-flex c-justify-center c-items-center">
            <div className="c-w-4/5">
              <p>
                {t('ONETRUST_VIDEO_OVERLAY', {
                  category: mapCookieCategory(cookieCategory),
                })}
              </p>
              <Button
                className="c-button c-my-3 c-mx-0"
                onClick={() => displayCookieSettings(cookieCategory)}>
                Cookie settings
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={onOpenModal}
          className="c-aspect-w-16 c-aspect-h-9 c-cursor-pointer">
          <div className="c-relative before:c-absolute before:c-content-play before:c-fill-white before:c-top-1/2 before:c-left-1/2 before:c--translate-x-1/2 before:c--translate-y-1/2 before:c-w-9 before:c-h-9 before:c-cursor-pointer">
            <img className="w-full c-cursor-pointer" src={image} alt={title} />
          </div>
          <Modal
            open={isOpen}
            onClose={onOpenModal}
            center={true}
            styles={{
              modal: {
                background: 'black',
                width: '100%',
                height: '100%',
                maxWidth: 'unset',
                margin: 'unset',
                paddingBottom: '90px',
              },
              overlay: {
                background: 'rgba(0, 0, 0, 0.5)',
              },
              closeButton: {
                background: 'white',
              },
            }}>
            <ReactPlayer {...props} width="100%" height="100%" pip controls />
          </Modal>
        </div>
      )}
    </>
  );
};

OneTrustReactPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string,
};

export default OneTrustReactPlayer;
